import React from "react"
import PropTypes from "prop-types"
import { PaymentSuccess } from "./paymentSuccess/paymentSuccess.component"
import PaymentSuccessNoPolicyContent from "./paymentSuccess/paymentSuccessNoPolicyContent.component"

export default function PaymentSuccessNoPolicyPage ({ location }) {
  const { email } = location.state || {}

  return (
    <PaymentSuccess location={location}>
      <PaymentSuccessNoPolicyContent email={email} />
    </PaymentSuccess>
  )
}

PaymentSuccessNoPolicyPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

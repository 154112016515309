import React from "react"
import PropTypes from "prop-types"
import { Bold } from "../../structure/theme/styles.component"
import { PaymentSuccessTitle } from "./paymentSuccessTitle.component"
import { PaymentSuccessConfirmation } from "./paymentSuccessConfirmation.component"
import { SuccessMessage } from "../../information/successMessage.component"
import { Trans, useTranslation } from "../../translation/translate.component"

export default function PaymentSuccessNoPolicyContent ({ email }) {
  const { t } = useTranslation()

  return (
    <>
      <PaymentSuccessTitle data-testid="payment_title">
        {t(`common:payment.page_payment_success_no_policy_title`)}
      </PaymentSuccessTitle>

      <SuccessMessage data-testid="payment_confirmation">
        {t(`common:payment.page_payment_success_no_policy_message`)}
      </SuccessMessage>

      <PaymentSuccessConfirmation>
        <p>
          {t(`common:payment.page_payment_success_confirmation_message`)}
          <br />
          <Trans
            t={t}
            i18nKey="common:payment.page_payment_success_confirmation_message2"
            components={{
              bold: <Bold />,
            }}
            values={{ email }}
          />
        </p>
      </PaymentSuccessConfirmation>
    </>
  )
}

PaymentSuccessNoPolicyContent.propTypes = {
  email: PropTypes.string.isRequired,
}
